var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-col",
        { staticClass: "pb-0", attrs: { cols: "12" } },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center text-break pb-0" },
            [_vm._v(" Ödev Planlama ")]
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          !_vm.loading
            ? _c("apex-chart", {
                attrs: {
                  type: "radialBar",
                  height: "300",
                  options: _vm.homeworkOptions,
                  series: _vm.homeworkOptions.series,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "p-0", attrs: { cols: "12" } },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "p-0 justify-center text-center text-break text-black-50",
              staticStyle: { "font-size": "14px" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.item.HomeworkCount) +
                  " ödevin " +
                  _vm._s(_vm.item.PlannedHomeworkCount) +
                  " tanesi için planlama yapılmış. "
              ),
              _c("br"),
              _vm._v(
                " Planlanan ödevlerin " +
                  _vm._s(_vm.item.StudentPlannedHomeworkCount) +
                  " tanesini öğrenci kendi planlamış. "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }