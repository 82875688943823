var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-title",
        [
          _vm._v(" Sınav Performansı "),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                to: { name: "sinav-raporu" },
                small: "",
                text: "",
                color: "primary",
              },
            },
            [
              _vm._v(" Sınav Raporları Ekranı "),
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-arrow-right"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("apex-chart", {
            attrs: {
              options: _vm.chartOptions,
              series: _vm.series,
              type: "bar",
              height: "400",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }