var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      !_vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-card-title",
            { staticClass: "pb-0" },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dense: "" } },
                [
                  _c("c-date-picker", {
                    attrs: { label: "Başlangıç Tarihi", "hide-details": "" },
                    on: { input: _vm.refresh },
                    model: {
                      value: _vm.filters.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "startDate", $$v)
                      },
                      expression: "filters.startDate",
                    },
                  }),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c("c-date-picker", {
                    attrs: { label: "Bitiş Tarihi", "hide-details": "" },
                    on: { input: _vm.refresh },
                    model: {
                      value: _vm.filters.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "endDate", $$v)
                      },
                      expression: "filters.endDate",
                    },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "warning", text: "" },
                      on: { click: _vm.clearFilters },
                    },
                    [_vm._v("SIFIRLA")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("c-date-picker", {
                        attrs: {
                          label: "Başlangıç Tarihi",
                          "hide-details": "",
                        },
                        on: { input: _vm.refresh },
                        model: {
                          value: _vm.filters.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "startDate", $$v)
                          },
                          expression: "filters.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("c-date-picker", {
                        attrs: { label: "Bitiş Tarihi", "hide-details": "" },
                        on: { input: _vm.refresh },
                        model: {
                          value: _vm.filters.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "endDate", $$v)
                          },
                          expression: "filters.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-card-text",
        { staticClass: "pt-0" },
        [
          _vm.filters.startDate == null
            ? _c("v-alert", { staticClass: "m-5", attrs: { type: "info" } }, [
                _vm._v("Raporları görüntülemek için tarih seçiniz. "),
              ])
            : _c(
                "div",
                [
                  _c("student-exam-report", {
                    key: _vm.compKey,
                    attrs: { filters: _vm.filters },
                  }),
                  _c("v-divider", { staticClass: "mx-8" }),
                  _c("student-homework-bar-chart", {
                    key: _vm.compKey,
                    attrs: { filters: _vm.filters },
                  }),
                  _c("v-divider", { staticClass: "mx-8" }),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("student-appointment-report", {
                                    key: _vm.compKey,
                                    attrs: { filters: _vm.filters },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("student-homework-planning-report", {
                                    key: _vm.compKey,
                                    attrs: { filters: _vm.filters },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("student-attendance-report", {
                                    key: _vm.compKey,
                                    attrs: { filters: _vm.filters },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-col",
                            [
                              _c("student-appointment-report", {
                                key: _vm.compKey,
                                attrs: { filters: _vm.filters },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("student-homework-planning-report", {
                                key: _vm.compKey,
                                attrs: { filters: _vm.filters },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("student-attendance-report", {
                                key: _vm.compKey,
                                attrs: { filters: _vm.filters },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("v-divider", { staticClass: "m-8" }),
                  _c("student-meeting-report", {
                    key: _vm.compKey,
                    attrs: { filters: _vm.filters },
                  }),
                  _c("v-divider", { staticClass: "m-8" }),
                  _c("student-stability-report", {
                    key: _vm.compKey,
                    attrs: { filters: _vm.filters },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("v-card-actions"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }