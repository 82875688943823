var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-col",
        { staticClass: "pb-0", attrs: { cols: "12" } },
        [
          _c("v-card-title", { staticClass: "justify-center text-break" }, [
            _vm._v(" Yoklama "),
          ]),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          !_vm.loading && _vm.series.length > 0
            ? _c("apex-chart", {
                attrs: {
                  type: "donut",
                  height: "275",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              })
            : _c(
                "v-alert",
                { staticClass: "m-5", attrs: { type: "info", text: "" } },
                [
                  _vm._v(
                    " Seçilen tarihler aralığında devamsızlık bulunamadı. "
                  ),
                ]
              ),
        ],
        1
      ),
      !_vm.loading && _vm.series.length > 0
        ? _c(
            "v-col",
            { staticClass: "p-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "justify-center text-center p-0 text-black-50",
                  staticStyle: { "font-size": "14px" },
                },
                [
                  _vm._v(
                    " Toplam " +
                      _vm._s(_vm.series.reduce((a, b) => a + b, 0)) +
                      " ders saati devamsızlık yapılmıştır. "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }